// @flow
// $FlowOptOut
import React, { useEffect, useMemo } from 'react'

import { min } from '@elder/common'
import { useEtSetSalesExecutiveHierarchyUsingPUT as useSetSalesExecutiveHierarchy } from '@elder/et-facade-et'
import { Box, Dialog, DialogContent } from '@mui/material'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { getCanEdit } from 'app'
import { ChangeSummary } from 'components'
import { ArchiveForm } from 'components/ArchiveForm/ArchiveForm'
import { DeleteChangeHistoryConfirmation } from 'components/ChangeSummary/DeleteChangeHistoryConfirmation'
import { InvoiceSummary } from 'components/InvoiceSummary'
import { AccountInfo } from 'features/accountInfo/AccountInfo'
import { CareAppraisalTargetVersionForm } from 'features/accountInfo/CareAppraisalTargetVersion'
import { CareAppraisalVersionForm } from 'features/accountInfo/CareAppraisalVersion'
import { ExecutiveHierarchyForm } from 'features/accountInfo/ExecutiveHierarchyForm'
import { CareModelTypeForm } from 'features/careModelFlag/CareModelTypeForm'
import { ChatContainer } from 'features/chat/ChatContainer'
import { CUSTOMER_MATCHING_PREFERENCES } from 'features/experiments/config'
import { NotesSectionContainer } from 'features/notes/NotesSectionContainer'
import { customerTemplates } from 'features/notes/templates/customerTemplates'
import { ADD_NOTE_FLOW } from 'features/notes/workflows'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { accountContext } from 'routes/account/accountContext'
import { ContactsContainer } from 'routes/account/contacts'
import { SolutionsSummary } from 'routes/account/solutions/SolutionsSummary'
import {
  BankDetails,
  BillingCycleForm,
  CareEnvironment,
  CareTypeForm,
  CarerBlacklist,
  FundingForm,
  PaymentAssuranceRequiredForm,
  PaymentTypeForm,
  RecipientsTable,
  RecommendedResourcesForm,
} from 'routes/account/summary/Summary/cards'
import AutomatedCommunicationsForm from 'routes/account/summary/Summary/cards/AutomatedCommunicationsForm'
import { Modals } from 'routes/account/summary/Summary/cards/Modals'
import PortalReadOnlyAccessForm from 'routes/account/summary/Summary/cards/PortalReadOnlyAccessForm'
import { ThirdPartyPlacementInfo } from 'routes/account/summary/Summary/cards/ThirdPartyPlacementInfo'
import {
  BlacklistCarerDialog,
  RemoveCarerFromBlacklistDialog,
} from 'routes/account/summary/Summary/dialogs'
import type { SummaryProps } from 'routes/account/summary/Summary/types'
import { describeServiceError } from 'utils/services'

const PageWrapper = styled.div`
  ${min.desktop(css`
    display: flex;
    > :first-child {
      margin-right: 32px;
    }
  `)}
`

const Column = styled.div`
  flex: 1;
`

const AccountWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ editMode }) =>
    editMode &&
    css`
      .acct__section:not(.acct__section--in-edit),
      .acct__section--full:not(.acct__section--in-edit) {
        opacity: 0.1;
        pointer-events: none;
      }
    `};
`

const FullWidth = styled.div`
  width: 100%;
`

export const Summary = ({
  accountId,
  customerId,
  account,
  canEdit,
  canEditPaymentAssuranceRequired,
  canManagePayments,
  paymentTypeOptions,
  canEditAutomatedComms,
  sectionEditMode,
  validationErrors,
  careTypesOptions,
  customerChurnReasonsOptions,
  customerChurnOutcomesOptions,
  onwardProviderOptions,
  fundingSourceOptions,
  fundingMethodTypeOptions,
  updateStatus,
  updateBasicAccountInfo,
  updatePaymentType,
  cancelDirectDebit,
  updateBillingCycle,
  isUpdatingFunding,
  updateFunding,
  isUpdatingPaymentAssuranceRequired,
  updatePaymentAssuranceRequired,
  editSection,
  cancelArchive,
  cancelEdit,
  financeUrl,
  changelogUrl,
  newRecipientUrl,
  getRecipientUrl,
  activeWorkflow,
  elderUsers,
  setActiveWorkflow,
  clearActiveWorkflow,
  addBlacklistedCarer,
  removeBlacklistedCarer,
  recommendedResourcesOptions,
  updateRecommendedResources,
  canClearAccountHistory,
  deleteChangeHistory,
  getAccountManagers,
  getAccountExecutives,
  updateArchiveDetails,
  updateAutomatedCommunications,
  reactivateAccount,
  updateCanAccessPortalWithReadOnlyPermissions,
  isReadOnlyAccessSaving,
  getSummaryData,
  getNotes,
  updateAccountExecutiveHierarchy,
  accountExecutiveHierarchy,
  fallbackAccountExecutive,
  updateCareModelType,
  regulatoryModelOptions,
  generateAccountExecutiveHierarchy,
  getTestGroup,
}: SummaryProps) => {
  useEffect(() => {
    getSummaryData()
    getNotes()
    getAccountManagers()
    getAccountExecutives()
    getTestGroup(CUSTOMER_MATCHING_PREFERENCES, accountId)
  }, [])

  const hasPermissionToChat = useSelector(getCanEdit)

  const isEditModeOn = sectionEditMode.length > 0
  let AccountInfoToDisplay = null

  const { mutate: setSalesExecutiveHierarchy } = useSetSalesExecutiveHierarchy({
    mutation: {
      onSuccess: () => {
        getSummaryData()
        applicationSuccess({
          title: 'Hierarchy Saved',
          message: 'Sales executive hierarchy successfully updated',
        })
      },
      onError: (error) => {
        applicationError(
          describeServiceError(
            error,
            'Unable to update sales executive hierarchy',
          ),
        )
      },
    },
  })

  if (sectionEditMode === 'archiveAccount') {
    const text =
      'All care taking place on or after the archive date will be cancelled ' +
      'immediately. The account will remain active until the archive date. (Completed care will not be affected)'
    AccountInfoToDisplay = (
      <ArchiveForm
        archiveAccount={updateStatus}
        cancelEdit={cancelEdit}
        churnOptions={customerChurnReasonsOptions}
        closureOutcomesOptions={customerChurnOutcomesOptions}
        onwardProviderOptions={onwardProviderOptions}
        title="Are you sure you want to archive this account?"
        text={text}
      />
    )
  } else if (sectionEditMode === 'editScheduledArchive') {
    const text =
      'All care taking place on or after the archive date will be cancelled ' +
      'immediately. The account will remain active until the archive date. (Completed care will not be affected)'
    AccountInfoToDisplay = (
      <ArchiveForm
        archiveAccount={updateStatus}
        cancelEdit={cancelEdit}
        churnOptions={customerChurnReasonsOptions}
        closureOutcomesOptions={customerChurnOutcomesOptions}
        onwardProviderOptions={onwardProviderOptions}
        title="Are you sure you want to archive this account?"
        text={text}
        archiveDetails={{
          churnReason: account.churnReason,
          churnOutcome: account.churnOutcome,
          onwardProvider: account.onwardProvider,
          churnDescription: account.churnDescription,
          archiveDate: account.archiveDate,
          archiveNoticeAt: account.archiveNoticeAt,
          potentialWinback: account.potentialWinback,
          churnSecondaryReasons: account.churnSecondaryReasons,
        }}
      />
    )
  } else if (sectionEditMode === 'editArchive') {
    const text =
      'All care taking place on or after the archive date will be cancelled ' +
      'immediately. The account will remain active until the archive date. (Completed care will not be affected)'
    AccountInfoToDisplay = (
      <ArchiveForm
        archiveAccount={updateArchiveDetails}
        editMode
        cancelEdit={cancelEdit}
        churnOptions={customerChurnReasonsOptions}
        closureOutcomesOptions={customerChurnOutcomesOptions}
        onwardProviderOptions={onwardProviderOptions}
        title="Are you sure you want to archive this account?"
        text={text}
        archiveDetails={{
          churnReason: account.churnReason,
          churnOutcome: account.churnOutcome,
          onwardProvider: account.onwardProvider,
          churnDescription: account.churnDescription,
          archiveDate: account.archiveDate,
          archiveNoticeAt: account.archiveNoticeAt,
          potentialWinback: account.potentialWinback,
          churnSecondaryReasons: account.churnSecondaryReasons,
        }}
      />
    )
  } else if (sectionEditMode === 'accountExecutiveHierarchy') {
    AccountInfoToDisplay = (
      <ExecutiveHierarchyForm
        title="Account Manager (edit mode)"
        cancelEdit={cancelEdit}
        accountManagers={account.accountManagers}
        accountExecutives={elderUsers}
        accountExecutiveHierarchy={accountExecutiveHierarchy}
        updateAccountExecutiveHierarchy={(hierarchy) =>
          updateAccountExecutiveHierarchy(accountId, hierarchy)
        }
        elderUserSelectorLabel="Add Account Executive:"
      />
    )
  } else if (sectionEditMode === 'salesExecutiveHierarchy') {
    AccountInfoToDisplay = (
      <ExecutiveHierarchyForm
        title="Sales executives (edit mode)"
        cancelEdit={cancelEdit}
        accountManagers={account.accountManagers}
        accountExecutives={elderUsers}
        accountExecutiveHierarchy={
          account?.relationshipContacts?.detailedSalesExecutiveHierarchy ?? []
        }
        updateAccountExecutiveHierarchy={(hierarchy) =>
          setSalesExecutiveHierarchy({
            accountId,
            data: { hierarchy, primary: hierarchy[0] },
          })
        }
        canRemove={false}
        elderUserSelectorLabel="Add Sales Executive:"
      />
    )
  } else if (sectionEditMode === 'careModelType') {
    AccountInfoToDisplay = (
      <CareModelTypeForm
        cancelEdit={cancelEdit}
        accountId={accountId}
        updateCareModelType={updateCareModelType}
        careModelFlag={account.regulatoryModel}
        careModelOptions={regulatoryModelOptions}
      />
    )
  } else if (sectionEditMode === 'automatedCommunicationsEnabled') {
    AccountInfoToDisplay = (
      <AutomatedCommunicationsForm
        automatedCommunicationsEnabled={account.automatedCommunicationsEnabled}
        updateAutomatedCommunications={updateAutomatedCommunications}
        cancelEdit={cancelEdit}
      />
    )
  } else if (
    sectionEditMode === 'canAccessPortalWithReadOnlyPermissionsEnabled'
  ) {
    AccountInfoToDisplay = (
      <PortalReadOnlyAccessForm
        canAccessPortalWithReadOnlyPermissions={
          account.canAccessPortalWithReadOnlyPermissions
        }
        updateCanAccessPortalWithReadOnlyPermissions={
          updateCanAccessPortalWithReadOnlyPermissions
        }
        cancelEdit={cancelEdit}
        isSaving={isReadOnlyAccessSaving}
      />
    )
  } else if (sectionEditMode === 'recommendedResources') {
    AccountInfoToDisplay = (
      <RecommendedResourcesForm
        recommendedResources={account.recommendedResources}
        updateRecommendedResources={updateRecommendedResources}
        options={recommendedResourcesOptions}
        cancelEdit={cancelEdit}
      />
    )
  } else if (sectionEditMode === 'careType') {
    AccountInfoToDisplay = (
      <CareTypeForm
        careType={account.careType || ''}
        careTypeOptions={careTypesOptions}
        updateBasicAccountInfo={updateBasicAccountInfo}
        cancelEdit={cancelEdit}
      />
    )
  } else if (sectionEditMode === 'careAppraisalVersion') {
    AccountInfoToDisplay = (
      <CareAppraisalVersionForm
        accountId={account.accountId}
        currentVersion={account.appraisalVersion}
        cancelEdit={cancelEdit}
      />
    )
  } else if (sectionEditMode === 'careAppraisalTargetVersion') {
    AccountInfoToDisplay = (
      <CareAppraisalTargetVersionForm
        accountId={account.accountId}
        currentTargetVersion={account.appraisalTargetVersion || null}
        cancelEdit={cancelEdit}
      />
    )
  }

  let BillingPaymentToDisplay = null
  if (sectionEditMode === 'bankDetailsBillingCycle') {
    BillingPaymentToDisplay = (
      <BillingCycleForm
        billingCycle={account.paymentDetails.billingCycleType}
        billingDayOfMonth={account.paymentDetails.billingDayOfMonth}
        cancelEdit={cancelEdit}
        updateBillingCycle={updateBillingCycle}
        validationErrors={validationErrors.billingCycle}
      />
    )
  } else if (sectionEditMode === 'bankDetailsPaymentDetails') {
    BillingPaymentToDisplay = (
      <PaymentTypeForm
        paymentDetails={account.paymentDetails}
        paymentTypeOptions={paymentTypeOptions}
        cancelEdit={cancelEdit}
        updatePaymentType={updatePaymentType}
        validationErrors={validationErrors.paymentDetails}
      />
    )
  } else if (sectionEditMode === 'bankDetailsFunding') {
    BillingPaymentToDisplay = (
      <FundingForm
        fundingSource={account.paymentDetails.fundingSource}
        fundingMethodType={account.paymentDetails.fundingMethodType}
        fundingProof={account.paymentDetails.fundingProof}
        fundingLocalAuthority={account.paymentDetails.fundingLocalAuthority}
        fundingSourceOptions={fundingSourceOptions.options}
        fundingMethodTypeOptions={fundingMethodTypeOptions.options}
        isUpdating={isUpdatingFunding}
        onCancel={cancelEdit}
        onUpdate={updateFunding}
      />
    )
  } else if (sectionEditMode === 'paymentAssuranceRequired') {
    BillingPaymentToDisplay = (
      <PaymentAssuranceRequiredForm
        paymentAssuranceRequired={
          account.paymentDetails.paymentAssuranceRequired
        }
        isUpdating={isUpdatingPaymentAssuranceRequired}
        onCancel={cancelEdit}
        onUpdate={updatePaymentAssuranceRequired}
      />
    )
  } else {
    BillingPaymentToDisplay = (
      <BankDetails
        account={account}
        canEdit={canEdit}
        canManagePayments={canManagePayments}
        canEditPaymentAssuranceRequired={canEditPaymentAssuranceRequired}
        editSection={editSection}
        cancelDirectDebit={cancelDirectDebit}
      />
    )
  }

  let ChangeSummaryToDisplay = null
  if (sectionEditMode === 'deleteHistory') {
    ChangeSummaryToDisplay = (
      <DeleteChangeHistoryConfirmation
        cancelEdit={cancelEdit}
        deleteChangeHistory={deleteChangeHistory}
      />
    )
  } else {
    ChangeSummaryToDisplay = (
      <ChangeSummary
        isEditModeOn={isEditModeOn}
        lastChangedBy={account.changelog.lastChangedBy}
        lastChangedAt={account.changelog.lastChangedAt}
        lastChangeDescription={account.changelog.lastChangeDescription}
        changelogUrl={changelogUrl}
        showClearAccountHistory={canClearAccountHistory}
        canClearAccountHistory={account.status === 'ARCHIVED'}
        deleteAccountHistory={() => editSection('deleteHistory')}
      />
    )
  }

  const accountContextValue = useMemo(
    () => (account && account.accountId ? { account } : null),
    [account],
  )

  return (
    <AccountWrapper editMode={isEditModeOn}>
      <Modals
        customerId={customerId}
        activeWorkflow={activeWorkflow}
        clearActiveWorkflow={clearActiveWorkflow}
      />
      <FullWidth>
        {accountContextValue && (
          <accountContext.Provider value={accountContextValue}>
            <SolutionsSummary />
          </accountContext.Provider>
        )}
        <PageWrapper>
          <Column>
            {account.careType === 'THIRD_PARTY' ? (
              <ThirdPartyPlacementInfo
                infoComplete={
                  account.environment.completedThirdPartyPlacementDetails ||
                  false
                }
                partnerName={
                  (account.thirdPartyPlacementDetails || {}).partnerName
                }
                accountId={account.accountId}
              />
            ) : (
              <RecipientsTable
                accountId={account.accountId}
                recipients={account.recipients}
                defocused={isEditModeOn}
                getRecipientUrl={getRecipientUrl}
                canEdit={canEdit}
                newRecipientUrl={newRecipientUrl}
              />
            )}
            <ContactsContainer accountId={customerId} />
            <Dialog open={AccountInfoToDisplay} onClose={cancelEdit}>
              <DialogContent sx={{ minWidth: '500px', minHeight: '350px' }}>
                {AccountInfoToDisplay}
              </DialogContent>
            </Dialog>
            <AccountInfo
              account={account}
              canEdit={canEdit}
              canEditAutomatedComms={canEditAutomatedComms}
              updateStatus={updateStatus}
              reactivateAccount={reactivateAccount}
              editSection={editSection}
              cancelArchive={cancelArchive}
              accountExecutiveHierarchy={accountExecutiveHierarchy}
              fallbackAccountExecutive={fallbackAccountExecutive}
              generateAccountExecutiveHierarchy={() => {
                generateAccountExecutiveHierarchy(accountId)
              }}
            />
            <CareEnvironment account={account} />
            {/* $FlowOptOut */}
            <CarerBlacklist
              // $FlowOptOut
              account={account}
              canEdit={canEdit}
              setActiveWorkflow={setActiveWorkflow}
              defocused={isEditModeOn}
            />
            <InvoiceSummary
              invoiceSummaryProp={account.invoiceSummary}
              financeUrl={financeUrl}
            />
            {BillingPaymentToDisplay}
            {ChangeSummaryToDisplay}
            <BlacklistCarerDialog
              clearActiveWorkflow={clearActiveWorkflow}
              addBlacklistedCarer={(professionalId, reason) => {
                addBlacklistedCarer(account.accountId, professionalId, reason)
              }}
              activeWorkflow={activeWorkflow}
            />
            <RemoveCarerFromBlacklistDialog
              clearActiveWorkflow={clearActiveWorkflow}
              removeBlacklistedCarer={removeBlacklistedCarer}
              activeWorkflow={activeWorkflow}
            />
          </Column>
          <Column>
            {hasPermissionToChat && account.accountId && (
              <Box sx={{ height: '50vh' }}>
                <ChatContainer
                  identifier={account.accountId}
                  conversationType="CUSTOMER_TO_ELDER"
                />
              </Box>
            )}
            <NotesSectionContainer
              width="HALF"
              notesEntityType="customer"
              notesEntityId={customerId}
              defocused={isEditModeOn}
              isReadOnly={!canEdit}
              createNote={() => setActiveWorkflow(ADD_NOTE_FLOW)}
              templates={customerTemplates}
            />
          </Column>
        </PageWrapper>
      </FullWidth>
    </AccountWrapper>
  )
}
